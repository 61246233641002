import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLanding = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToAdmin = () => redirectLoggedInTo(['admin']);

const routes: Routes = [
  {
    path: '',
    data: {authGuardPipe: redirectLoggedInToAdmin },
    loadChildren: () => import('./modules/website/website.module').then( m => m.WebsitePageModule)
  },
  {
    path: 'admin',
    data: {authGuardPipe: redirectUnauthorizedToLanding },
    loadChildren: () => import('./modules/admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'authentication',
    data: {authGuardPipe: redirectLoggedInToAdmin },
    loadChildren: () => import('./modules/authentication/authentication.module').then( m => m.AuthenticationPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
