// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig:{
    apiKey: 'AIzaSyDrt858MEk1ZtrBEGuAb3pwWxSaufeFAG8',
    authDomain: 'banquet-team.firebaseapp.com',
    projectId: 'banquet-team',
    storageBucket: 'banquet-team.appspot.com',
    messagingSenderId: '133540717819',
    appId: '1:133540717819:web:29c4fd11d62f302c6f7c5e',
    measurementId: 'G-NNK98KBCSS'
  },
  application:{
    selector: 'careers'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
